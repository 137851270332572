import React from "react";
import Grid from "@mui/material/Grid";

import { StaticImage } from "gatsby-plugin-image";

const Stickyy = () => {
  return (
    <>
      <Grid container maxWidth={"lg"} className="sticky">
        <Grid
          container
          xs={10}
          sm={5}
          style={{
            paddingLeft: "20px",
          }}
          position={{ xs: "relative", sm: "sticky" }}
          top={{ xs: 0, sm: "7em" }}
          marginTop={{ sm: 1, xs: 0 }}
        >
          <h2 className="sticky_h1">
            The
            <b style={{ color: "#FFA100" }}> smartest</b> way to manage your
            data.
          </h2>
        </Grid>
        <Grid
          container
          item
          xs={10}
          sm={5}
          id="right--section"
          style={{ textAlign: "center" }}
        >
          <Grid item xs={12}>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StaticImage
                  src="./sticky/gdpr.png"
                  alt="GDPR"
                  loading="lazy"
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  style={{ width: "80%" }}
                />
              </div>
            </div>
            <h4>
              <div className="sticky_text_connect">
                Keep your data secure in a GDPR compliance platform.
              </div>
            </h4>
            <h4 style={{ fontWeight: "100", marginTop: "0" }}>
              <div>
                and easily access them from any device, any time, from anywhere.
              </div>
            </h4>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "2em" }}>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StaticImage
                  src="./sticky/optimize.png"
                  alt="Optimize your workflow"
                  loading="lazy"
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                />
              </div>
            </div>
            <h4>
              <div className="sticky_text_connect">Optimize your workflow</div>
            </h4>
            <h4 style={{ fontWeight: "100", marginTop: "0" }}>
              <div>
                and bid farewell to time-consuming tasks like uploading,
                downloading, and dealing with USB devices. With automation, your
                workflow is streamlined for maximum efficiency.
              </div>
            </h4>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "2em" }}>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StaticImage
                  src="./sticky/cleanup.png"
                  alt="Cleanup"
                  loading="lazy"
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  style={{
                    maxHeight: "400px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  imgStyle={{
                    objectFit: "contain",
                    maxHeight: "100%",
                    maxWidth: "100%",
                  }}
                />
              </div>
            </div>
            <h4>
              <div className="sticky_text_connect">
                Never run out of storage or lose your videos again.
              </div>
            </h4>
            <h4 style={{ fontWeight: "100", marginTop: "0" }}>
              <div>
                Away from your personal videos, your medical data will be
                instantly uploaded to a secure cloud and easily accessible by
                you.
              </div>
            </h4>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Stickyy;
