import * as React from "react";
import PropTypes from "prop-types";
import { Container, Grid, Box, Tab, Tabs, Typography } from "@mui/material";
import img from "./share.webp";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import sessions from "./tabs/sessions.gif";
import find from "./tabs/find.webp";
import edit from "./tabs/edit.webp";
import night from "./tabs/night.webp";
import dedicated from "./dedicated.png";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "5em",
            justifyContent: "center",
          }}
        >
          <Box justifyContent="center" display="flex" width="100%">
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  background: "#8F4FFF",
                },
              }}
              sx={{
                "& button.Mui-selected": { color: "#8F4FFF" },
              }}
              style={{
                borderRadius: "10px",
                boxShadow: "3px 4px 9px 0px rgba(0, 0, 0, 0.25)",
                paddingBottom: "10px",
                marginBottom: "3em",
              }}
              variant="scrollable"
              scrollButtons={false}
              fullWidth
              aria-label="scrollable prevent tabs example"
            >
              <Tab
                label="Sessions"
                icon={
                  <svg
                    width="50"
                    height="51"
                    viewBox="0 0 50 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ height: "24px", width: "24px" }}
                  >
                    <path
                      d="M24.878 2C37.7197 2 48.128 12.4082 48.128 25.25C48.128 38.0917 37.7197 48.5 24.878 48.5"
                      stroke="currentColor"
                      stroke-width="3.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.9343 47.0769C14.3613 46.1392 11.9949 44.7804 9.9386 43.0547"
                      stroke="currentColor"
                      stroke-width="3.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.9386 7.44413C11.9949 5.71588 14.3613 4.35704 16.9343 3.42188"
                      stroke="currentColor"
                      stroke-width="3.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 21.2149C2.47792 18.4946 3.42342 15.9397 4.75642 13.6328"
                      stroke="currentColor"
                      stroke-width="3.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 29.2812C2.47792 32.0015 3.42342 34.5564 4.75642 36.8633"
                      stroke="currentColor"
                      stroke-width="3.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24.878 19.2305V31.2688"
                      stroke="currentColor"
                      stroke-width="3.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M30.8972 25.25H24.878H18.8588"
                      stroke="currentColor"
                      stroke-width="3.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                {...a11yProps(0)}
              />
              <Tab
                label="Smart Search"
                icon={<SearchOutlinedIcon />}
                {...a11yProps(1)}
              />
              <Tab
                label="Link Sharing"
                icon={
                  <svg
                    width="20"
                    height="23"
                    viewBox="0 0 20 23"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ height: "24px", width: "24px" }}
                  >
                    <path
                      d="M14.9042 3.67806C14.6051 3.32485 14.2501 3.04467 13.8593 2.85351C13.4685 2.66235 13.0497 2.56396 12.6267 2.56396C12.2038 2.56396 11.7849 2.66235 11.3942 2.85351C11.0034 3.04467 10.6483 3.32485 10.3492 3.67806L6.9336 7.71263C6.32957 8.42594 5.99023 9.3934 5.99023 10.4022C5.99023 11.411 6.32957 12.3784 6.9336 13.0917C7.53763 13.8051 8.35687 14.2058 9.2111 14.2058C10.0653 14.2058 10.8846 13.8051 11.4886 13.0917L11.8928 12.6134C11.8928 12.6134 11.7108 12.0096 11.4886 11.7472C11.2664 11.4838 10.7543 11.2689 10.7543 11.2689L10.35 11.7472C10.048 12.1039 9.63828 12.3043 9.2111 12.3043C8.78391 12.3043 8.37422 12.1039 8.07215 11.7472C7.77008 11.3905 7.60038 10.9067 7.60038 10.4022C7.60038 9.89771 7.77008 9.41389 8.07215 9.05717L11.4886 5.0226C11.7907 4.666 12.2003 4.46572 12.6274 4.46581C13.0545 4.4659 13.4641 4.66635 13.7661 5.02307C14.068 5.37979 14.2376 5.86356 14.2376 6.36795C14.2375 6.87234 14.0677 7.35603 13.7657 7.71263L13.1964 8.3849C13.1964 8.3849 13.591 8.68728 13.839 8.9811C14.0886 9.27492 14.335 9.72944 14.335 9.72944L14.9042 9.05717C15.2033 8.70398 15.4406 8.28467 15.6024 7.8232C15.7643 7.36172 15.8476 6.86711 15.8476 6.36761C15.8476 5.86811 15.7643 5.3735 15.6024 4.91203C15.4406 4.45055 15.2033 4.03125 14.9042 3.67806Z"
                      fill="currentColor"
                    />
                    <path
                      d="M13.1972 9.72931C12.8981 9.37596 12.543 9.09566 12.1521 8.90442C11.7613 8.71318 11.3424 8.61475 10.9193 8.61475C10.4962 8.61475 10.0773 8.71318 9.68646 8.90442C9.29561 9.09566 8.9405 9.37596 8.64141 9.72931L8.20579 10.2447C8.20579 10.2447 8.42159 10.8152 8.64382 11.0767C8.86605 11.3391 9.34434 11.5892 9.34434 11.5892L9.77995 11.0739C9.92952 10.8972 10.1071 10.7571 10.3025 10.6615C10.4979 10.5659 10.7074 10.5167 10.9189 10.5167C11.1304 10.5167 11.3399 10.5659 11.5353 10.6615C11.7307 10.7571 11.9083 10.8972 12.0578 11.0739C12.2074 11.2505 12.3261 11.4602 12.407 11.691C12.488 11.9217 12.5296 12.1691 12.5296 12.4189C12.5296 12.6687 12.488 12.916 12.407 13.1468C12.3261 13.3776 12.2074 13.5873 12.0578 13.7639L8.64141 17.7985C8.49184 17.975 8.31428 18.1151 8.11888 18.2106C7.92348 18.3061 7.71406 18.3553 7.50257 18.3552C7.07546 18.3552 6.66587 18.1547 6.36391 17.798C6.06195 17.4413 5.89235 16.9575 5.89243 16.4531C5.8925 15.9487 6.06225 15.465 6.36431 15.1084L6.99156 14.3677C6.99156 14.3677 6.58655 14.0263 6.36351 13.7639C6.14208 13.5014 5.85221 13.0232 5.85221 13.0232L5.22577 13.7639C4.92117 14.1156 4.67867 14.5351 4.51232 14.9982C4.34597 15.4614 4.25907 15.9589 4.25664 16.4619C4.25422 16.965 4.33633 17.4636 4.49821 17.9289C4.6601 18.3943 4.89854 18.817 5.19974 19.1728C5.50093 19.5285 5.85889 19.8102 6.2529 20.0014C6.6469 20.1927 7.06912 20.2898 7.49511 20.287C7.92109 20.2842 8.34238 20.1817 8.73458 19.9853C9.12678 19.7889 9.48209 19.5026 9.77995 19.143L13.1964 15.1084C13.4955 14.7552 13.7327 14.3359 13.8946 13.8745C14.0565 13.413 14.1398 12.9184 14.1398 12.4189C14.1398 11.9194 14.0565 11.4248 13.8946 10.9633C13.7327 10.5018 13.4963 10.0825 13.1972 9.72931Z"
                      fill="currentColor"
                    />
                  </svg>
                }
                {...a11yProps(2)}
              />
              <Tab
                label="Video Edition"
                icon={
                  <svg
                    width="68"
                    height="68"
                    viewBox="0 0 68 68"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ height: "24px", width: "24px" }}
                  >
                    <path
                      d="M8.5 45.332H28.3333"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M46.75 22.668H8.5"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M25.5 56.6654H14.1667C11.0358 56.6654 8.5 54.1295 8.5 50.9987V16.9987C8.5 13.8679 11.0358 11.332 14.1667 11.332H48.1667"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21.25 22.6654V11.332M34 22.6654V11.332"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21.25 56.6654V45.332"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M59.5 22.6667H42.5"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M42.5 11.332H53.8333C56.9642 11.332 59.5 13.8679 59.5 16.9987V25.4987"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M46.7499 11.332V22.6654"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M58.9253 29.1651C58.1773 28.419 57.164 28 56.1076 28C55.0513 28 54.038 28.419 53.29 29.1651L34.2419 48.222C32.0944 50.3698 31.1221 56.7992 31.0136 57.5212C30.9867 57.7052 30.9998 57.8927 31.0519 58.0712C31.1041 58.2497 31.1942 58.4148 31.3161 58.5552C31.438 58.6956 31.5887 58.8079 31.758 58.8848C31.9274 58.9615 32.1113 59.0009 32.2972 59C32.3581 59.0002 32.4189 58.9959 32.4792 58.9871C33.2062 58.8837 39.6344 57.9112 41.7767 55.7582L60.8351 36.7117C61.5811 35.9636 62 34.9501 62 33.8935C62 32.8369 61.5811 31.8235 60.8351 31.0753L58.9253 29.1651ZM34.8217 52.5345L37.4753 55.1886C36.2981 55.5716 35.0989 55.8833 33.8842 56.1224C34.124 54.9086 34.4371 53.7104 34.8217 52.5345ZM59.004 34.8854L39.9559 53.9371C39.9509 53.9425 39.9447 53.9469 39.9377 53.9498C39.9309 53.9527 39.9234 53.9541 39.9159 53.9539C39.9087 53.954 39.9014 53.9526 39.8947 53.9496C39.8881 53.9468 39.8821 53.9425 39.8771 53.9371L36.0678 50.127C36.0626 50.1219 36.0585 50.1158 36.0556 50.1089C36.0529 50.1022 36.0514 50.095 36.0514 50.0876C36.0514 50.0803 36.0529 50.073 36.0556 50.0663C36.0585 50.0596 36.0626 50.0534 36.0678 50.0482L55.1159 30.9965C55.3794 30.7343 55.736 30.5872 56.1076 30.5872C56.4794 30.5872 56.8359 30.7343 57.0993 30.9965L59.004 32.9016C59.2666 33.1649 59.4139 33.5216 59.4139 33.8935C59.4139 34.2653 59.2666 34.6221 59.004 34.8854Z"
                      fill="currentColor"
                    />
                  </svg>
                }
                {...a11yProps(3)}
              />
              <Tab
                label="Night Upload"
                icon={
                  <svg
                    width="87"
                    height="62"
                    viewBox="0 0 87 62"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ height: "24px", width: "24px" }}
                  >
                    <path
                      d="M62.6896 2.68137C63.4231 2.63276 64.1506 2.62709 64.8632 2.65637C65.5956 2.6884 66.3163 2.75585 67.0217 2.86153C67.4187 2.9206 67.692 3.29224 67.6333 3.69137C67.5874 3.99803 67.3574 4.23172 67.0738 4.29631C64.8208 4.80939 63.1511 5.04763 61.2952 7.58498C59.4688 10.0793 58.4719 13.2085 58.683 16.5375L58.6831 16.561C58.9285 20.319 60.6603 23.6223 63.2708 25.9277C65.878 28.2326 69.3577 29.5355 73.0946 29.2989L73.1181 29.2988C75.8086 29.1208 78.2612 28.172 80.2809 26.6744C82.3712 25.1261 82.033 24.888 82.9664 22.4334C83.1092 22.0561 83.5303 21.8694 83.9023 22.0125C84.1851 22.1229 84.3623 22.3901 84.3693 22.6773C84.4941 28.1488 82.456 33.2058 79.0154 36.9786C75.5907 40.7334 70.7714 43.2187 65.3138 43.5708C59.6973 43.9342 54.4667 41.975 50.5493 38.5166C46.63 35.0479 44.0264 30.0872 43.6646 24.441C43.3112 18.9273 45.1604 13.7832 48.4519 9.8784C51.7584 5.96221 56.517 3.28667 61.9613 2.7408C62.0668 2.72866 62.1887 2.71884 62.321 2.70709L62.3243 2.70755L62.6896 2.68137Z"
                      fill="currentColor"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.6907 25.8633C8.40425 27.0078 2 34.1191 2 42.8149C2 52.3046 9.60696 60.0002 18.9874 60.0002H56.3597C63.8647 60.0002 69.9496 53.8444 69.9496 46.2519C69.9496 38.6595 63.8647 32.5037 56.3597 32.5037C56.3597 21.1133 47.234 11.8813 35.9748 11.8813C27.0156 11.8813 19.4257 17.7347 16.6907 25.8633Z"
                      fill="white"
                      stroke="currentColor"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                {...a11yProps(4)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Grid
              container
              justifyContent="space-between"
              style={{ alignItems: "center" }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={sessions} style={{ maxWidth: "100%" }}></img>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <h1 style={{ color: "#8F4FFF", marginTop: "1em" }}>
                  Session organization.
                </h1>
                <div>
                  Create tailored sessions,{" "}
                  <b>allocate records to your patients</b>, and embellish them
                  with personal <b>comments and tags</b>. Redefine your
                  recording management experience for peak{" "}
                  <b>
                    productivity and organization in a dedicated and safe cloud.
                  </b>
                </div>
              </Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Grid
              container
              justifyContent="space-between"
              style={{ alignItems: "center" }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={find} style={{ maxWidth: "100%" }}></img>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <h1 style={{ color: "#8F4FFF", marginTop: "1em" }}>
                  Easiest way to find your medical data.
                </h1>
                <div>
                  Better search capabilities, enabling users to conduct{" "}
                  <b>advanced searches by seamlessly</b> combining patient
                  information, session dates, and tags, resulting in a more
                  refined and precise search experience.
                </div>
              </Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Grid
              container
              justifyContent="space-between"
              style={{ alignItems: "center" }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={img} style={{ maxWidth: "100%" }}></img>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <h1 style={{ color: "#8F4FFF", marginTop: "1em" }}>
                  No downloads for sharing your data.
                </h1>
                <div>
                  Effortlessly share your media{" "}
                  <b>with colleagues or provide your patients</b> with a
                  superior educational <b>experience, with a dedicated link</b>,
                  all without the hassle of downloads.
                </div>
              </Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Grid
              container
              justifyContent="space-between"
              style={{ alignItems: "center" }}
            >
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={edit}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "500px",
                    objectFit: "contain",
                  }}
                ></img>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <h1 style={{ color: "#8F4FFF", marginTop: "1em" }}>
                  Capture & Edit.
                </h1>
                <p>
                  Unlock the power of <b>post-recording video editing</b> right
                  within the app!{" "}
                </p>
                <div style={{ marginBottom: "2em" }}>
                  With a range of tools at your fingertips, you can effortlessly
                  trim, rotate, crop, and fine-tune your videos, adjusting
                  brightness, contrast, and more to perfection. Tailor your
                  videos like never before!
                </div>
                <a href="/microrec-app" target="_blank">
                  <img src={dedicated} style={{ maxWidth: "100%" }}></img>
                </a>
              </Grid>
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <Grid
              container
              justifyContent="space-between"
              style={{ alignItems: "center" }}
            >
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={night}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "500px",
                    objectFit: "contain",
                  }}
                ></img>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <h1 style={{ color: "#8F4FFF" }}>Your data, your time. </h1>
                <div style={{ marginBottom: "2em" }}>
                  Now, you have even <b>more control over your data</b>. You can
                  decide whether your videos and images are{" "}
                  <b>instantly uploaded or you can do it later</b> when you have
                  more time.
                </div>
                <a href="/microrec-app" target="_blank">
                  <img src={dedicated} style={{ maxWidth: "100%" }}></img>
                </a>
              </Grid>
            </Grid>
          </CustomTabPanel>
        </Grid>
      </Grid>
    </Container>
  );
}
