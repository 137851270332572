import React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import third from "./gif.webp";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} round {...props} />
))(({ theme }) => ({
  borderBottom: `0.5px solid white`,
  boxShadow: "none",
  backgroundColor: "transparent",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        style={{ color: "white" }}
        sx={{ fontSize: "0.9rem" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgb(32, 32, 38)",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "white",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    color: "white",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    color: "#8F4FFF",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid #8F4FFF",
  color: "white",
  backgroundColor: "#202026",
}));

export default function FAQsSection(data) {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <section id="FAQ-microREC">
      <Grid container spacing={2} paddingBottom={4}>
        <Grid item paddingTop={6} paddingBottom={4} xs={12}>
          <h2 style={{ color: "white", paddingBottom: "4", fontSize: "30px" }}>
            The future proof-way to manage your medical imaging.
          </h2>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel3bh-header"
              sx={{
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  color: "#8F4FFF",
                },
              }}
            >
              <h3
                style={{
                  color: "inherit",
                  margin: "0",
                  fontWeight: "500",
                  fontSize: "1rem",
                }}
              >
                O1. Instant access from anywhere.
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ padding: 1 }}>
                With MicroREC Connect, you can easily keep your professional
                medical imaging{" "}
                <b>files organized and separate from personal data.</b>
                This ensures that your work-related images and videos are stored
                securely, reducing the risk of confusion or accidental mix-ups.
              </Typography>
              {/* <Link
                to="/32-Enhancing-Patient-Education-and-Evolution-through-MicroREC-and-MicroREC-App/"
                style={{ color: "#8F4FFF", fontWeight: "600" }}
              >
                Check how to enhance patient evolution with Connect &#8594;
              </Link> */}
              <GatsbyImage
                image={getImage(data.data.faqImage3)}
                style={{
                  width: "100%",
                  maxHeight: "400px",
                  objectFit: "cover",
                  marginTop: "20px",
                  borderRadius: "5px",
                }}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2bh-content"
              id="panel3bh-header"
              sx={{
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  color: "#8F4FFF",
                },
              }}
            >
              <h3
                style={{
                  color: "inherit",
                  margin: "0",
                  fontWeight: "500",
                  fontSize: "1rem",
                }}
              >
                O2. Separate your professional files form your personal one.
              </h3>
            </AccordionSummary>
            <AccordionDetails id="">
              <Typography sx={{ padding: 1 }}>
                By <b>visually explaining diagnoses</b> and treatment plans,
                patients can better understand their medical conditions. This
                deeper understanding makes patients more engaged and willing to
                <b>adhere to treatment, improving overall outcomes</b>. MicroREC
                Connect makes it easy and friendly to show, share and organize
                all the data.
              </Typography>
              {/* <Link
                to="/21-Digital-Transformation-in-Ophthalmology-Recording-and-Sharing-Live-Surgery/"
                style={{ color: "#8F4FFF", fontWeight: "600" }}
              >
                Learn more &#8594;
              </Link> */}
              <GatsbyImage
                image={getImage(data.data.faqImage4)}
                style={{
                  width: "100%",
                  maxHeight: "400px",
                  objectFit: "cover",
                  marginTop: "20px",
                  borderRadius: "5px",
                }}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              sx={{
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  color: "#8F4FFF",
                },
              }}
            >
              <h3
                style={{
                  color: "inherit",
                  margin: "0",
                  fontWeight: "500",
                  fontSize: "1rem",
                }}
              >
                O3. Improve the patient education.{" "}
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ padding: 1 }}>
                With MicroREC Connect, you can{" "}
                <b>
                  record, save, and access imaging files securely from any
                  device
                </b>
                , without limits. No matter where you are, your data is always
                at your fingertips.
              </Typography>

              <img
                src={third}
                style={{
                  width: "100%",
                  maxHeight: "400px",
                  objectFit: "cover",
                  marginTop: "20px",
                  borderRadius: "5px",
                }}
                alt=""
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </section>
  );
}
