import React from "react";
import { Container, Box } from "@mui/material";
import FAQ from "./faq";
import FAQMobile from "./faq-mobile";
import { useStaticQuery, graphql } from "gatsby";

export default function Accordion() {
  const data = useStaticQuery(graphql`
    query {
      faqImage3: file(relativePath: { regex: "/(accordion/instant_access)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      faqImage4: file(relativePath: { regex: "/(accordion/separate)/" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <Box
      sx={{
        backgroundColor: "#202026",
      }}
      marginBottom={{ xs: 6, sm: 10 }}
      style={{ paddingBottom: "3em" }}
    >
      <Container maxWidth="lg">
        <Box display={{ xs: "none", sm: "block" }}>
          <FAQ data={data} />
        </Box>
        <Box display={{ xs: "block", sm: "none" }}>
          <FAQMobile data={data} />
        </Box>
      </Container>
    </Box>
  );
}
